import React, { useCallback, useState } from 'react'
import SearchInput from './SearchInput'
import { api, addCsrfHeader } from 'components/api'
import { supplierSearchPath } from 'components/routes/api'
import { debounce } from 'lodash-es'
import PropTypes from 'prop-types'

const supplierSearchByName = name => {
  const request = {
    url: supplierSearchPath(),
    headers: addCsrfHeader({}, document),
    params: { query: name }
  }
  return api.get(request)
}

const SharedSupplierSearch = ({ SearchResultsComponent, placeholder }) => {
  const [currentValue, setCurrentValue] = useState('')
  // apiData will be null if a response is pending
  const [apiData, setApiData] = useState(null)

  const responseSetter = async name => {
    const apiResponse = await supplierSearchByName(name)
    return setApiData(apiResponse?.data || [])
  }

  const debouncedQuery = useCallback(debounce(responseSetter, 300), [])

  const handleChange = e => {
    setCurrentValue(e.target.value || '')
    setApiData(null)
    debouncedQuery(e.target.value || '')
  }

  const renderSearchResultsIfValue = (apiData, currentValue) =>
    currentValue.length > 0
      ? (<SearchResultsComponent searchResults={apiData} supplierName={currentValue} />)
      : null

  return (
    <>
      <SearchInput
        currentValue={currentValue}
        handleChange={handleChange}
        placeholder={placeholder || ''}
      />
      {renderSearchResultsIfValue(apiData, currentValue)}
    </>
  )
}

SharedSupplierSearch.propTypes = {
  SearchResultsComponent: PropTypes.func.isRequired,
  placeholder: PropTypes.string
}

export default SharedSupplierSearch
