import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import SupplierResultShape from './prop_shapes/api_v2_supplier_result'
import LoadingDots from '../LoadingDots'
import StatesContext from './StatesContext'
import { appendQueryToPath } from '../utils'

function serviceAreaText (states, national) {
  const nationwide = 'Nationwide'
  if (national) {
    return nationwide
  } else if (!states || states.length === 0) {
    return ''
  } else if (states.length === 1) {
    const stateLookup = useContext(StatesContext)
    return stateLookup[states[0]]
  } else if (states.length < 50) {
    return 'Select States'
  } else if (states.length >= 50) {
    return nationwide
  }
}

const HeaderContent = ({ resultsCount }) => {
  const resultPluralForm = resultsCount === 1 ? 'Result' : 'Results'
  const resultsCountTitle = <span>{resultsCount} {resultPluralForm}</span>
  const serviceAreaTitle = resultsCount === 0 ? null : <span>Service Area</span>
  return (
    <>
      {resultsCountTitle}
      {serviceAreaTitle}
    </>
  )
}

HeaderContent.propTypes = {
  resultsCount: PropTypes.number
}

const UnauthedSearchResultsHeader = ({ searchResults }) => {
  const headerContainer = (searchResults === null)
    ? <LoadingDots show />
    : <HeaderContent resultsCount={searchResults.length} />

  return (
    <div className='unauthed-supplier-search-header'>
      {headerContainer}
    </div>
  )
}

UnauthedSearchResultsHeader.propTypes = {
  searchResults: PropTypes.arrayOf(SupplierResultShape)
}

const UnauthedSearchResultsFooter = () => (
  <div className='unauthed-supplier-search-footer'>
    <span>
      <i className='fas fa-lock lock-icon' />
      <a className='sign-up-link' data-open='sign-up-modal'>Sign Up</a>
      or
      <a className='sign-in-link' data-open='sign-in-modal'>Log In</a>
      to search by keyword, commodity, or business designation
    </span>
  </div>
)

const SupplierServiceArea = ({ states, national }) => {
  const serviceArea = serviceAreaText(states, national)
  if (serviceArea === '') { return null }
  return (
    <div className='service-area'>
      <div className='supplier-service-area-button'>
        {serviceArea}
      </div>
    </div>
  )
}

SupplierServiceArea.propTypes = {
  states: PropTypes.arrayOf(PropTypes.string),
  national: PropTypes.bool
}

const SupplierSearchResult = ({ result, trackingData }) => {
  const { page, name, states, national } = result || {}
  const trackingParams = {
    unauthed_home_page_search_term: trackingData.searchTerm,
    unauthed_home_page_result_count: trackingData.resultCount
  }
  const trackedLink = appendQueryToPath(page, trackingParams)
  return (
    <a href={trackedLink}>
      <div className='unauthed-supplier-search-result'>
        <div className='name'>
          {name}
        </div>
        <SupplierServiceArea states={states} national={national} />
      </div>
    </a>
  )
}

SupplierSearchResult.propTypes = {
  result: SupplierResultShape,
  trackingData: PropTypes.shape({
    resultCount: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired
  })
}

const SupplierSearchResults = ({ searchResults, searchTerm }) => {
  if (searchResults === null) { return null }
  const resultCount = searchResults.length
  const trackingData = { searchTerm, resultCount }
  return (
    <div className='unauthed-supplier-search-results'>
      {
        searchResults.map(result => (
          <SupplierSearchResult
            key={result.id}
            result={result}
            trackingData={trackingData}
          />
        ))
      }
    </div>
  )
}

SupplierSearchResults.propTypes = {
  searchResults: PropTypes.arrayOf(SupplierResultShape),
  searchTerm: PropTypes.string
}

const UnauthedSearchResults = ({ searchResults, supplierName }) => {
  if (supplierName.length === 0) return null

  return (
    <div className='unauthed-supplier-search-results-container'>
      <UnauthedSearchResultsHeader searchResults={searchResults} />
      <SupplierSearchResults searchResults={searchResults} searchTerm={supplierName} />
      <UnauthedSearchResultsFooter />
    </div>
  )
}

UnauthedSearchResults.propTypes = {
  searchResults: PropTypes.arrayOf(SupplierResultShape),
  supplierName: PropTypes.string
}

export default UnauthedSearchResults
