// TODO: CONVERT TO TYPESCRIPT
import PropTypes from 'prop-types'

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  location: PropTypes.string,
  slug: PropTypes.string,
  states: PropTypes.arrayOf(PropTypes.string),
  national: PropTypes.bool,
  page: PropTypes.string
})
