import React from 'react'
import PropTypes from 'prop-types'
import SearchIcon from './SearchIcon'

const SearchInput = ({ currentValue, handleChange, placeholder }) => {
  return (
    <div className='input-group-search'>
      <SearchIcon />
      <input
        className='input-field-search'
        aria-label='dynamic-input-label'
        autoComplete='off'
        autoFocus
        onChange={handleChange}
        placeholder={placeholder}
        type='text'
        value={currentValue || ''}
      />
    </div>
  )
}

SearchInput.propTypes = {
  currentValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
}

export default SearchInput
